export const environment = {
  firebase: {
    apiKey: "AIzaSyDmRszeRiNbLuCrkWycIGQy3rOedbUmnW0",
    authDomain: "kostenmarke.firebaseapp.com",
    databaseURL: "https://kostenmarke-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "kostenmarke",
    storageBucket: "kostenmarke.appspot.com",
    messagingSenderId: "910770895215",
    appId: "1:910770895215:web:e0eef4f5e4c8c15dfd109a"
  },
  elastic: {
    "backend-app-key": "private-iy57qyihkaw5yiyi1qsjaj2q"
  },
  emulatorFunctionAdress: 'http://localhost:5001/euflight-x-keven/europe-west3',
  functionAdress: 'https://europe-west3-euflight-x-keven.cloudfunctions.net',
  elasticsearch: 'search-7vzrg7ams8teijsh6883r21d',
  recaptchaEnterpriceSiteKey: '6LctwVQfAAAAANDKr7wEwHcudCgnYPst7PvyEvQ1',
  recaptcha3SiteKey: '6LctwVQfAAAAANDKr7wEwHcudCgnYPst7PvyEvQ1',
  appCheckDebugToken: 'CAE111E8-84B3-447B-99B6-A9F763E6CE3F',
  stripePubKey: "pk_test_51PGPxFBmzLI87PzTc1ANZQYAPrnWvC6EZ68lVbcxxBFVdXvAjtkMkecg5kGeOIPs2htDiCyfZd0K78QCg044bfP0001Oe9ndt4",
  stripePrivKey: "sk_test_51PGPxFBmzLI87PzTKm9UtEnecRnBfdqPhwk8I0zeR3DL0DOfX8ZGyd4gGcVVRNKE30XCZgmlrVih3erxuQ0hpWD000VBnmIdna",
  production: false,
  useEmulators: {
    http: false,
    firestore: false,
    auth: false,
  },
  project: 'PROD'
};
