import { EventEmitter, Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  showLoading$: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public async notification(title: string, message: string, type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success') {
    return Swal.fire(title, message, type)
  }
}
