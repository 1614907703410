import { inject, Injectable } from '@angular/core';
import { applyActionCode, Auth, connectAuthEmulator, createUserWithEmailAndPassword, getAuth, IdTokenResult, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BackendService } from './backend.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, filter, first, firstValueFrom, from, Observable, of } from 'rxjs';
import { Customer } from '../core/models/customer';
import { Database, fromRef, object, onValue, query, ref, set, stateChanges } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private db = inject(Database);

  public auth: Auth = getAuth();
  //public auth: Auth = inject(Auth);
  public user: Customer | User | null = null;
  public user$ = new BehaviorSubject<User | null>(null);
  public roles: Array<string> = [];
  public idTokenResult: IdTokenResult | null = null;
  constructor(private router: Router, private backend: BackendService) {

    if (environment.useEmulators.auth) {
      console.log("auth emulator", this.auth);
      connectAuthEmulator(this.auth, 'http://localhost:9099', { disableWarnings: true });
    }

    onAuthStateChanged(this.auth, async (user: User | null) => {
      if (!user) {
        this.user = null;
        console.log('Not logged In');
      } else {
        console.log("logged in", user);
        this.user = user;
        this.user$.next(user);
        this.idTokenResult = await user.getIdTokenResult();
        this.listenForTokenRefresh(user.uid);
        console.log("token", this.idTokenResult);

        if (this.idTokenResult.claims["role"]) {
          this.roles = this.idTokenResult.claims["role"] as Array<string>;
        }
      }
    });
  }

  public applyVerification(actionCode: string) {
    return applyActionCode(this.auth, actionCode);
  }

  listenForTokenRefresh(uid: string) {
    const mref = ref(this.db, `metadata/${uid}`);
    onValue(mref, snapshot => {
      const snap = snapshot.val();
      const now = new Date().getTime() + (1 * 60 * 1000);
      if (snap && snap.refreshTime && snap.refreshTime < now) {
        this.updateFreshTime();
        console.log("refresh token");
        this.refreshIdTokenResult();
      }
    });
  }

  updateFreshTime() {
    if (this.auth.currentUser) {
      const mref = ref(this.db, `metadata/${this.auth.currentUser.uid}`);
      const now = new Date().getTime();
      
      return set(mref, { refreshTime: now + (3 * 60 * 1000) });
    }
    return of(null);
  }
  public async refreshIdTokenResult() {
    if (this.auth.currentUser) {
      this.idTokenResult = await this.auth.currentUser?.getIdTokenResult(true);
      console.log("refreshed token", this.idTokenResult);
      
      if (this.idTokenResult.claims["role"]) {
        this.roles = this.idTokenResult.claims["role"] as Array<string>;
      }
    }
  }

  public resendVerification() {
    if (!this.auth.currentUser) {
      return;
    }
    return sendEmailVerification(this.auth.currentUser);
  }
  public register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);

  }
  public login(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(async (user: any) => {
        console.log("logged In");

        await this.router.navigate(['/home']);
      })
      .catch((error: any) => {
        console.log("error", error.code, error.message);
        if (error.code === "auth/invalid-email")
          this.backend.notification("Fehler", "Falsche E-Mail", "error")
        if (error.code === "auth/user-not-found")
          this.backend.notification("Fehler", "Kein Benutzer mit den Zugangsdaten gefunden!", "error")
        if (error.code == "auth/invalid-credential")
          this.backend.notification("Fehler", "Sie haben eine <b>falsche</b> Email oder ein <b>falsches</b> Passwort eingetragen!", "error")
      })
  }
  public logout() {
    signOut(this.auth)
      .then(() => {
        this.router.navigate(['/auth/login']);
      })
  }
  public resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
  }
  waitForUser() {
    return firstValueFrom(this.user$.pipe(filter(user => user !== null), first()));
  }
}
